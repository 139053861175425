<template>
    <div class="mechanism_detail">
        <div class="header">
            <div class="header_content">
                <div class="breadcrumb">
                    <span class="name">当前位置：</span>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/training/' }">职业培训</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/training/mechanism' }">机构目录</el-breadcrumb-item>
                        <el-breadcrumb-item>机构详情</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="detail flex">
                  <img :src="detail.logo" :alt="detail.name+'logo'" class="logo err_logo">
                    <div class="info">
                        <h1>{{detail.name}}</h1>
                        <p>行政区划：<span>{{detail.provinceId}}-{{detail.cityId}} <span v-if="detail.threecityId">-{{detail.threecityId}}</span></span></p>
                        <div>
                            <pre v-if="detail.linkMaile">邮       箱：</pre>
                            <span>{{detail.linkMaile}}</span></div>
                        <p>单位地址：<span>{{detail.address}}</span></p>
                        <div class="text" v-html="detail.describtion">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <h2>培训项目</h2>
            <ul class="list flex flex-wrap">
                <li v-for="(n,i) in list" :key="i" class="box">
                    <router-link :to="'./trainDetail?id='+n.id" target="_blank" :title="n.trainName" class="name">
                        <div class="top relative">
                            <img :src="n.picUrl || ''" alt="" class="logo img err_image" :title="n.trainName">
                        </div>
                        <img src="@/static/train/registration.png" alt="" class="status" v-if="n.ing == 2">
                        <img src="@/static/train/over.png" alt="" class="status" v-if="n.ing == 3">
                        <img src="@/static/train/registered.png" alt="" class="status" v-if="n.ing == 4">
                        <div class="bottom">
                            <div class="time">
                                {{n.appplyBeginTime+'-'+n.appplyEndTime}}
                            </div>
                            <img src="@/static/select.png" alt="" class="img inline_block" v-if="n.isTui == '是'">
                            <span class="line_clamp2">{{n.trainName}}</span>
                            <div class="address">
                                <ul>
                                    <li class="line_clamp1"><span v-for="(item,index) in JSON.parse(n.disType)"
                                                                  :key="index">{{item}}</span></li>
                                    <li>{{n.maxNumber}}人报名</li>
                                </ul>
                                <span class="line_clamp1"><i class="icon el-icon-location" title="位置："></i> {{n.trainAddress}}</span>
                            </div>
                        </div>
                    </router-link>

                </li>
            </ul>
        </div>
        <!-- v-if="this.page.total > 12" -->
        <div class="pages" v-if="this.page.total > 12">
            <el-pagination
                    background
                    class="work_pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.pageNum"
                    :page-size="page.pageSize"
                    layout="prev, pager, next, total, jumper"
                    prev-text="上一页"
                    next-text="下一页"
                    :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "mechanismDetail",
        data() {
            return {
                detail: [],
                list: [],
                page: {
                    pageNum: 1,
                    pageSize: 12,
                    total: 0,
                    id: '',
                },
                loading: false,
            }
        },
        methods: {
            async info() {
                this.loading = true;
                let res = await this.$api.getTrainOrgDetailApi({id: this.page.id});
                if (res.data.success) {
                    this.loading = false;
                    this.detail = res.data.data;
                    this.detailList();
                } else {
                    this.loading = false;
                }
            },
            detailList() {
                this.loading = true;
                this.$api.getFwjgTrainSearchListApi(this.page).then(res => {
                    if (res.data.success) {
                        this.loading = false;
                        this.list = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.loading = false;
                    }
                }).catch(err => {
                    this.loading = false;
                })
            },
            //分页
            handleCurrentChange(val) {
                this.page.pageNum = val;
                this.detailList();
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                 this.detailList();
            },
        },
        created() {
            this.page.id = this.$route.query.id;
            this.info();
          //判断是否移动端
          this.$mobile('https://lzmz.cdpee.org.cn/#/pages/train/institutionDetails?id='+this.$route.query.id);
        },
        mounted() {
            this.$emit('goRouter', '/training/')
        }
    }
</script>

<style scoped>
    @import "~assets/css/train.css";
</style>
